import React from "react"

import { H2, Wrapper, RichText as RichTextComponent, Box } from "components"

function RichText({ content, title }) {
  return (
    <>
      <Wrapper as="section" pt={[5, 6]}>
        <Box width="100%" maxWidth={800}>
          {title && title.text && <H2 children={title.text} />}
          {content && (
            <RichTextComponent content={content} fontSize={[1, 2, 3]} />
          )}
        </Box>
      </Wrapper>
    </>
  )
}

export default RichText
