import React from "react"
import css from "@styled-system/css"

import { AspectRatio, Box, Wrapper } from "components"

function Matterport({ url }) {
  return (
    <Box>
      <Wrapper flush pt={[5, 6]}>
        <AspectRatio ratio="4:3">
          <iframe
            src={url}
            title="vrTour"
            height="90%"
            width="100%"
            css={css({ border: 0 })}
          />
        </AspectRatio>
      </Wrapper>
    </Box>
  )
}

export default Matterport
