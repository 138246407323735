// Adapted from https://github.com/angeloashmore/gatsby-source-prismic/blob/master/docs/previews-manual-setup.md

import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { usePrismicPreview } from "gatsby-source-prismic"

import { Box, H1, Wrapper } from "components"

import Community from "../../templates/community/"
import Campaign from "../../templates/landingPage/"
import Highrise from "../../templates/highrise/"
import Post from "../../templates/post/"
import QuickPossession from "../../templates/quickPossession/"

const RESOLVE_PREVIEW_LINK = doc => {
  // Pretty URLs for known types
  if (doc.type === "home") {
    return "/"
  }
  if (doc.type === "showhome") {
    return "/showhomes/"
  }
  if (doc.type === "move_in_ready_home") {
    return "/quick-possessions/" + doc.uid + "/"
  }
  if (doc.type === "landing_page") {
    return "/campaigns/" + doc.uid + "/"
  }
  if (doc.type === "community") {
    return "/communities/" + doc.uid + "/"
  }
  if (doc.type === "highrise") {
    return "/highrises/" + doc.uid + "/"
  }
  if (doc.type === "post") {
    return "/updates/" + doc.uid + "/"
  }
  // Fallback for other types, in case new custom types get created
  if (doc.type === "move_in_ready") {
    return "/quick-possessions/"
  }
  return "/" + doc.slugs[0] + "/"
}

// Note that the `location` prop is taken and provided to the `usePrismicPreview` hook.
const PreviewPage = ({ location }) => {
  const [previewActive, setPreviewActive] = useState("")
  const { isPreview, previewData, path } = usePrismicPreview({
    // The repositoryName value from your `gatsby-config.js`.
    repositoryName: "partners-homes",
  })

  // This useEffect runs when values from usePrismicPreview update. When
  // preview data is available, this will save the data globally and redirect to
  // the previewed document's page.
  useEffect(() => {
    // If this is not a preview, skip.
    //   null = Not yet determined if previewing.
    //   true = Preview is available.
    //   false = Preview is not available.
    if (isPreview === false) return

    // Save the preview data to somewhere globally accessible. This could be
    // something like a global Redux store or React context.

    // We'll just put it on window.
    window.__PRISMIC_PREVIEW_DATA__ = previewData

    // Navigate to the correct template
    // Navigate to the correct template
    if (previewData) {
      const keys = Object.keys(previewData)
      if (
        previewData[keys[0]].type === "community" ||
        previewData[keys[0]].type === "highrise" ||
        previewData[keys[0]].type === "move_in_ready_home" ||
        previewData[keys[0]].type === "landing_page" ||
        previewData[keys[0]].type === "post"
      ) {
        setPreviewActive(previewData[keys[0]].type)
      } else {
        navigate(RESOLVE_PREVIEW_LINK(previewData[keys[0]]))
      }
    }
  }, [isPreview, previewData, path])

  return (
    <>
      {!previewActive && (
        <Wrapper as="section" pt={[5, 6]}>
          <Box mb={4}>
            <H1 children={`Loading Preview`} mb={3} />
          </Box>
        </Wrapper>
      )}
      {previewActive === "community" && <Community />}
      {previewActive === "highrise" && <Highrise />}
      {previewActive === "move_in_ready_home" && <QuickPossession />}
      {previewActive === "landing_page" && <Campaign />}
      {previewActive === "post" && <Post />}
    </>
  )
}

export default PreviewPage
