import React, { useState } from "react"
import css from "@styled-system/css"

import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Image,
  Sidebar,
  Text,
  Wrapper,
} from "components"

const ratio = "3:2"

const ExpandButton = ({ ...rest }) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 64 64"
    fill="none"
    css={css({ verticalAlign: "top" })}
    {...rest}
  >
    <title>Expand</title>
    <rect width={64} height={64} rx={32} fill="#fff" />
    <path
      d="M18.926 19.347l25.456 25.456M18.801 33.615V19.216h14.4M29.988 44.8h14.4V30.4"
      stroke="#3A3937"
      strokeWidth={2}
    />
  </svg>
)

const CustomImage = ({ src, alt, order, onClick, ...rest }) => (
  <Box order={order}>
    <Box
      css={css({
        cursor: "pointer",
        ".expandButton": { opacity: 0.3, transition: "opacity 250ms ease-out" },
        "&:hover": {
          ".expandButton": { opacity: 1 },
        },
      })}
      onClick={onClick}
      {...rest}
    >
      <AspectRatio ratio={ratio}>
        <Image src={src} alt={alt} css={css({ height: "100%" })} />
        <ExpandButton
          className="expandButton"
          css={css({
            position: "absolute",
            bottom: ["8px", "16px"],
            right: ["8px", "16px"],
          })}
        />
      </AspectRatio>
    </Box>
  </Box>
)

const Row = ({ image1, image2, alt1, alt2, onClick1, onClick2 }) => (
  <Box>
    <Flex
      css={css({
        m: [-2, -3],
        "> *": {
          width: "50%",
          p: [2, 3],
        },
      })}
    >
      <CustomImage src={image1} alt={alt1} onClick={onClick1} />
      <CustomImage src={image2} alt={alt2} onClick={onClick2} />
    </Flex>
  </Box>
)

const ColumnOne = ({ images, onClick }) => (
  <Box>
    <Flex
      flexDirection="column"
      css={css({
        m: [-2, -3],
        "> *": {
          width: "100%",
          p: [2, 3],
        },
      })}
    >
      {images[0] && images[1] && (
        <Row
          image1={images[0].image}
          alt1={images[0].image.alt}
          image2={images[1].image}
          alt2={images[1].image.alt}
          onClick1={() => {
            onClick(0)
          }}
          onClick2={() => {
            onClick(1)
          }}
        />
      )}
      {images[2] && (
        <CustomImage
          src={images[2].image}
          alt={images[2].image.alt}
          onClick={() => {
            onClick(2)
          }}
        />
      )}
    </Flex>
  </Box>
)

const ColumnTwo = ({ images, onClick }) => (
  <Box>
    <Flex
      flexDirection="column"
      css={css({
        m: [-2, -3],
        "> *": {
          width: "100%",
          p: [2, 3],
        },
      })}
    >
      {images[3] && (
        <CustomImage
          src={images[3].image}
          alt={images[3].image.alt}
          order={[1, -1]}
          onClick={() => {
            onClick(3)
          }}
        />
      )}
      {images[4] && images[5] && (
        <Row
          image1={images[4].image}
          alt1={images[4].image.alt}
          image2={images[5].image}
          alt2={images[5].image.alt}
          onClick1={() => {
            onClick(4)
          }}
          onClick2={() => {
            onClick(5)
          }}
        />
      )}
    </Flex>
  </Box>
)

function Gallery({ id, images, allImages }) {
  const [scrollPosition, setScrollPosition] = useState("")
  const [showAll, setShowAll] = useState(false)

  if (images && images[0]) {
    return (
      <>
        <Wrapper as="section" pt={[5, 6]}>
          <Box css={css({ overflow: "hidden" })}>
            <Flex
              flexWrap="wrap"
              css={css({
                m: [-2, -3],
                "> *": {
                  width: ["100%", "50%"],
                  p: [2, 3],
                },
              })}
            >
              <ColumnOne
                images={images}
                onClick={index => {
                  setScrollPosition(id + "image" + index)
                  setShowAll(true)
                }}
              />
              {images.length > 3 && (
                <ColumnTwo
                  images={images}
                  onClick={index => {
                    setScrollPosition(id + "image" + index)
                    setShowAll(true)
                  }}
                />
              )}
            </Flex>
          </Box>
          {allImages.length > 6 && (
            <Box mt={4}>
              <Button
                children={`Show all photos`}
                variant="alt"
                onClick={() => {
                  setScrollPosition("")
                  setShowAll(true)
                }}
              />
            </Box>
          )}
        </Wrapper>
        <Sidebar
          id={id}
          scrollPosition={scrollPosition}
          showDialog={showAll}
          setShowDialog={setShowAll}
          side="right"
          maxWidth={1200}
          children={
            <>
              <Box
                pt={[48, 64]}
                pb={5}
                px={[3, 4]}
                css={css({ "> *:not(:last-of-type)": { mb: [3, 4] } })}
              >
                {allImages
                  .sort((a, b) => {
                    if (a.featured === "Yes" && b.featured !== "Yes") {
                      return -1
                    }
                    if (b.featured === "Yes" && a.featured !== "Yes") {
                      return 1
                    }
                    return 0
                  })
                  .map((image, index) => (
                    <Box id={id + "image" + index} key={id + "image" + index}>
                      <Box mx={[-3, 0]}>
                        <Image src={image.image} alt={image.image.alt} />
                      </Box>
                      <Text children={image.caption} mt={2} fontSize={[0, 1]} />
                    </Box>
                  ))}
              </Box>
            </>
          }
        />
      </>
    )
  } else {
    return null
  }
}

export default Gallery
