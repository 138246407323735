import React, { useEffect, useState } from "react"
import css from "@styled-system/css"

import { useHeader } from "contexts/header"

import {
  AspectRatio,
  Box,
  CommunityRegisterButton,
  Flex,
  H1,
  Image,
  RichText,
  Wrapper,
} from "components"

function Hero({
  color,
  community,
  description,
  image,
  salesSimplicityId,
  title,
  video,
}) {
  const { setHeaderMode } = useHeader()

  useEffect(() => {
    if ((image && image.url) || (video && video.url)) {
      setHeaderMode("transparent")
    } else {
      setHeaderMode("solid")
    }
  }, [])

  return (
    <>
      <Box
        as="section"
        position="relative"
        css={css({
          overflow: "hidden",
        })}
      >
        {video && video.url && (
          <AspectRatio ratio="21:9">
            <video
              preload="metadata"
              autoPlay
              muted
              playsInline
              css={css({
                position: "absolute !important",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                userSelect: "none",
                pointerEvents: "none",
                "@supports (-ms-ime-align:auto)": {
                  top: "50%",
                  left: " 50%",
                  transform: "translateX(-50%) translateY(-50%)",
                  minWidth: "100%",
                  minHeight: "100%",
                  width: "auto",
                  height: "auto",
                  overflow: "hidden",
                },
                "@media screen and (-ms-high-contrast: none)": {
                  top: "50%",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-50%)",
                  minWidth: "100%",
                  minHeight: "100%",
                  width: "auto",
                  height: "auto",
                  overflow: "hidden",
                },
              })}
            >
              <source src={video.url + "#t0.5"} type="video/mp4" />
            </video>
          </AspectRatio>
        )}
        {(!video || !video.url) && image && image.fluid && (
          <AspectRatio ratio="21:9">
            <Image
              src={image}
              alt={image.alt}
              loading="eager"
              css={css({
                position: "absolute !important",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              })}
            />
          </AspectRatio>
        )}
      </Box>
      <Box py={5} bg={color}>
        <Wrapper
          css={css({
            position: "relative",
            overflow: "hidden",
          })}
        >
          <Flex
            flexWrap="wrap"
            css={css({
              m: [-2, -4],
              "> *": {
                p: [2, 4],
              },
            })}
          >
            {title && (
              <Box width={["100%", 1 / 2]}>
                <H1 as="h1" color="white">
                  {title}
                </H1>
              </Box>
            )}

            {description && (
              <Box width={["100%", 1 / 2]}>
                <RichText
                  content={description}
                  fontSize={[1, 2, 3]}
                  css={css({
                    "> *": {
                      color: "white",
                      "&:first-child": { marginTop: 0 },
                    },
                  })}
                />
                {salesSimplicityId && (
                  <CommunityRegisterButton
                    color={color}
                    community={community}
                    heading={title}
                    salesSimplicityId={salesSimplicityId}
                  />
                )}
              </Box>
            )}
          </Flex>
        </Wrapper>
      </Box>
    </>
  )
}

export default Hero
