import React from "react"
import css from "@styled-system/css"

import { Flex, Form, Box, H1, RichText, Wrapper } from "components"

function SignupForm({ community, description, salesSimplicityId, title, uid }) {
  if (salesSimplicityId) {
    return (
      <Wrapper as="section" pt={[5, 6]}>
        <Flex
          flexWrap="wrap"
          css={css({
            m: [-2, -4],
            "> *": {
              p: [2, 4],
            },
          })}
        >
          <Box width={["100%", 1 / 2]}>
            {title && <H1 mb={4}>{title.text}</H1>}
            {description && (
              <RichText content={description} fontSize={[1, 2, 3]} />
            )}
          </Box>

          <Form
            community={community}
            formFields={[
              "consent",
              "email",
              "firstName",
              "lastName",
              "message",
              "phone",
              "postal",
            ]}
            heading="Get in touch"
            id="landing-page-contact-form"
            name="landing-page-contact-form"
            salesSimplicityId={salesSimplicityId}
            submitId={community.uid + "_get-in-touch"}
            submitLabel="Get in touch"
            width={["100%", 1 / 2]}
          />
        </Flex>
      </Wrapper>
    )
  } else {
    return null
  }
}
export default SignupForm
