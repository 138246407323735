import React from "react"
import css from "@styled-system/css"

import { Button, Flex, Box, H1, Text, RichText, Wrapper } from "components"

function Location({
  address,
  address_coordinates,
  address_link,
  color,
  contact_information,
  hours,
  title,
  uid,
}) {
  return (
    <Wrapper as="section" pt={[5, 6]}>
      <Flex
        flexWrap="wrap"
        css={css({
          m: [-2, -4],
          "> *": {
            p: [2, 4],
          },
        })}
      >
        <Box width={["100%", 1 / 2]}>
          {title && <H1 mb={4}>{title.text}</H1>}
          <Flex
            flexWrap="wrap"
            css={css({
              m: [-3, -4],
              "> *": {
                p: [3, 4],
              },
            })}
          >
            <Box width={["100%", 1 / 2]}>
              {contact_information && (
                <Box>
                  <Text fontSize={0}>Contact Information</Text>
                  <RichText
                    content={contact_information}
                    css={css({
                      "*": {
                        m: "0 !important",
                      },
                    })}
                  />
                </Box>
              )}
              {address && address_link && (
                <Box mt={4}>
                  <Text fontSize={0}>Address</Text>
                  <Text>
                    <a href={address_link.url} target="_blank" rel="noopener">
                      <RichText
                        content={address}
                        css={css({
                          "*": {
                            m: "0 !important",
                          },
                        })}
                      />
                    </a>
                  </Text>
                </Box>
              )}
              <Flex
                flexWrap="wrap"
                mt={3}
                css={css({ "> *:not(:last-of-type)": { mr: [3, 4] } })}
              >
                {address_link && (
                  <Button
                    css={css({ bg: color })}
                    id={uid + "_directions"}
                    children={`Directions`}
                    mt={3}
                    variant="default"
                    as="a"
                    href={address_link.url}
                    target="_blank"
                    rel="noopener"
                  />
                )}
              </Flex>
            </Box>
            {hours && (
              <Box width={["100%", 1 / 2]}>
                <Box>
                  <Text fontSize={0}>Hours of operation</Text>
                  <RichText
                    content={hours}
                    css={css({
                      "*": {
                        m: "0 !important",
                      },
                    })}
                  />
                </Box>
              </Box>
            )}
          </Flex>
        </Box>
        {address_link && address_coordinates && (
          <Box width={["100%", 1 / 2]}>
            <Box
              as="a"
              href={address_link.url}
              target="_blank"
              rel="noopener"
              display="block"
              height="100%"
              minHeight={[400, 600]}
              bg="text"
              style={{
                backgroundImage:
                  "url(https://maps.googleapis.com/maps/api/staticmap?center=" +
                  address_coordinates.latitude +
                  "%2c%20" +
                  address_coordinates.longitude +
                  "&markers=anchor:bottomleft%7Cicon:http%3A%2F%2Fpartnershomes.netlify.com%2Fimages%2F" +
                  "salescenterpin" +
                  ".png%7C" +
                  address_coordinates.latitude +
                  "%2c%20" +
                  address_coordinates.longitude +
                  "&zoom=14&size=640x640&scale=2&key=" +
                  process.env.GATSBY_GOOGLE_KEY +
                  "&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e)",
              }}
              css={css({
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              })}
            />
          </Box>
        )}
      </Flex>
    </Wrapper>
  )
}

export default Location
