import React from "react"
import { graphql } from "gatsby"

import { CallToAction, SEO } from "components"
import { usePreviewData } from "utils"

import Gallery from "./_gallery"
import Matterport from "./_matterport"
import Hero from "./_hero"
import Image from "./_image"
import Location from "./_location"
import RichText from "./_richText"
import SignupForm from "./_signupForm"

function LandingPage({ data, location, setTagColor }) {
  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)

  return (
    <>
      <SEO
        title={MERGED_DATA.prismicLandingPage.data.title}
        description={MERGED_DATA.prismicLandingPage.data.description}
        image={MERGED_DATA.prismicLandingPage.data.image}
      />
      <Hero
        uid={MERGED_DATA.prismicLandingPage.uid}
        color={MERGED_DATA.prismicLandingPage.data.color}
        image={MERGED_DATA.prismicLandingPage.data.image}
        video={MERGED_DATA.prismicLandingPage.data.video}
        title={
          MERGED_DATA.prismicLandingPage.data.title &&
          MERGED_DATA.prismicLandingPage.data.title.text
        }
        description={MERGED_DATA.prismicLandingPage.data.description}
        salesSimplicityId={
          MERGED_DATA.prismicLandingPage.data &&
          MERGED_DATA.prismicLandingPage.data.community &&
          MERGED_DATA.prismicLandingPage.data.community.document &&
          MERGED_DATA.prismicLandingPage.data.community.document.data &&
          MERGED_DATA.prismicLandingPage.data.community.document.data
            .sales_simplicity_id
        }
      />
      {MERGED_DATA.prismicLandingPage.data.body &&
        MERGED_DATA.prismicLandingPage.data.body.length > 0 &&
        MERGED_DATA.prismicLandingPage.data.body.map(
          ({ __typename, primary, items }, index) => {
            switch (__typename) {
              case "PrismicLandingPageBodyCallToAction":
                return (
                  <>
                    <CallToAction
                      align={primary.cta_side}
                      pattern={primary.cta_pattern}
                      image={primary.cta_image}
                      video={primary.cta_video}
                      heading={primary.cta_title}
                      text={primary.cta_description}
                      button={primary.cta_button_text}
                      link={primary.cta_link}
                      bg={MERGED_DATA.prismicLandingPage.data.color}
                      key={`callToAction${index}`}
                    />
                  </>
                )
              case "PrismicLandingPageBodyGallery": {
                return (
                  <Gallery
                    images={items.slice(0, 6)}
                    allImages={items}
                    id={`gallery${index}`}
                    key={`gallery${index}`}
                  />
                )
              }
              case "PrismicLandingPageBodyImage":
                return <Image {...primary} key={`image${index}`} />
              case "PrismicLandingPageBodyMatterport": {
                return (
                  <Matterport
                    url={primary?.matterport_embed_link?.url}
                    id={`matterport${index}`}
                    key={`matterport${index}`}
                  />
                )
              }
              case "PrismicLandingPageBodyRichText":
                return <RichText {...primary} key={`richText${index}`} />
              case "PrismicLandingPageBodySignupForm":
                return (
                  <SignupForm
                    {...primary}
                    color={MERGED_DATA.prismicLandingPage.data.color}
                    key={`signupForm${index}`}
                    salesSimplicityId={
                      primary &&
                      primary.community &&
                      primary.community.document &&
                      primary.community.document.data &&
                      primary.community.document.data.sales_simplicity_id
                    }
                    uid={MERGED_DATA.prismicLandingPage.uid}
                    community={primary?.community?.document?.data}
                  />
                )
              case "PrismicLandingPageBodyLocation":
                return (
                  <Location
                    {...primary}
                    color={MERGED_DATA.prismicLandingPage.data.color}
                    key={`location${index}`}
                    uid={MERGED_DATA.prismicLandingPage.uid}
                  />
                )
              default:
                return null
            }
          }
        )}
    </>
  )
}

export const query = graphql`
  query LandingPageQuery($uid: String!) {
    prismicLandingPage(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        image {
          url
          fluid(maxWidth: 1600) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        video {
          url
        }
        description {
          text
          html
        }
        community {
          document {
            ... on PrismicCommunity {
              uid
              data {
                sales_simplicity_id
              }
            }
          }
        }
        color
        body {
          __typename
          ... on PrismicLandingPageBodyCallToAction {
            primary {
              cta_image {
                fluid(maxWidth: 1600) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              cta_video {
                url
              }
              cta_title {
                text
              }
              cta_description {
                html
              }
              cta_link {
                link_type
                url
              }
              cta_button_text
              cta_pattern
              cta_side
            }
          }
          ... on PrismicLandingPageBodyGallery {
            items {
              image {
                fluid(maxWidth: 1600) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              caption
              featured
            }
          }
          ... on PrismicLandingPageBodyImage {
            primary {
              image {
                fluid(maxWidth: 1600) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicLandingPageBodyMatterport {
            primary {
              matterport_embed_link {
                url
              }
            }
          }
          ... on PrismicLandingPageBodyLocation {
            primary {
              title {
                text
              }
              address_coordinates {
                latitude
                longitude
              }
              address {
                html
              }
              address_link {
                url
              }
              hours {
                html
              }
              contact_information {
                html
              }
            }
          }
          ... on PrismicLandingPageBodyRichText {
            primary {
              title {
                text
              }
              content {
                html
              }
            }
          }
          ... on PrismicLandingPageBodySignupForm {
            primary {
              title {
                text
              }
              description {
                html
              }
              community {
                document {
                  ... on PrismicCommunity {
                    uid
                    data {
                      sales_simplicity_id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default LandingPage
