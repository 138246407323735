import React from "react"
import css from "@styled-system/css"

import { AspectRatio, Box, Image as ImageComponent } from "components"

function Image({ image }) {
  if (image && image.fluid) {
    return (
      <Box
        as="section"
        position="relative"
        pt={[5, 6]}
        css={css({
          overflow: "hidden",
        })}
      >
        <AspectRatio ratio="21:9">
          <ImageComponent
            src={image}
            alt={image.alt}
            loading="eager"
            css={css({
              position: "absolute !important",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            })}
          />
        </AspectRatio>
      </Box>
    )
  } else {
    return null
  }
}

export default Image
